html, body {
    background-color: #212a3e;
    height: 100%;
}
body {
    color: #fff;
    text-align: center !important;
}
#root {
    height: 100%;
}
.cover_container {
    max-width: 42em;
}
.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}
.btn-support {
    color: #fff;
    background-color: #7cf391;
}
.height {
    height: 100%;
}
.item-order {
    margin-bottom: 10px;
    border: none;
    border-radius: 8px;
}
p {
    margin-bottom: 0;
    color: #fff;
}
a:hover {
    text-decoration: none;
}
.order-link {
    border-radius: 8px;
    padding: 5px;
}
.alert {
    position: fixed;
    top: 15px;
    margin: 0 10px 0 10px
}
.loader {
    margin: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(9px);
    z-index: 9999;
    position: fixed;
    top: 0;
}
.modal-for-form {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    backdrop-filter: blur(9px);
}

.modal-content {
    background-color: #3a3b3c;
    margin: 40% auto;
    padding: 15px;
    max-width: 400px;
}
.text-order {
    font-weight: 600;
    margin-left: 10px;
}
.img {
    background-color: #fff;
    width: 100%;
}
textarea {
    resize: none;
}
.form-close {
    text-align: left;
    font-size: 20px;
}
.checkbox {
    width: 20px;
    height: 20px;
}
.line {
    border: 2px solid;
    width: 30%;
    margin: 10px 0 10px 0;
}
.color {
    background-color: #f7c2c2;
}
.color:hover {
    background-color: #fdb5b5;
}
.temporary-list {
    font-size: 18px;
    color: #495057;
}
.fixed-bottom.bg-light.tab-bottom {
    padding: 10px;
}
.tab-link {
    color: #aba7a7;
}
.tab-link.active {
    color: #d50a0a;
}