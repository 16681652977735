@import '~bootstrap';
@import './App.css';

.active {
    border: 2px solid #fff;
    border-top: none;
    background-color: transparent;
    font-weight: 600;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: transparent;
}
.nav-link:hover {
    color: #fff;
}
.nav-link {
    color: #fff;
}
.text-order {
    font-weight: 400;
    margin-left: 5px;
    font-size: 16px;
}
.font-weight-bold {
    font-size: 18px;
}
